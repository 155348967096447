<!--  -->
<template>
  <div class=''>
    <div style="width: 200px; height: 100px; background: #999;" @click.capture="clickme">
		<a href="tel: 13456789"  @click.stop="clickA">点击a标签</a>
	</div>    
  <input type="text" v-model.number="msg">
  <span>{{ msg }}</span>
  <div v-cloak>
    {{ msg }}
  </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      msg: 'he'
    }
  },
  computed: {},
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    setTimeout(() => {
      this.msg = 'adrgafgfd'
    }, 2000);
  },
  methods: {
    clickme(e) {
      // 阻止时间冒泡
      // e.stopPropagation()
      alert("点击div")
    },
    clickA(e) {
      e.stopPropagation()
      alert("点击a标签")
    }
  },
}
</script>
<style lang='scss' scoped>
[v-cloak] {
  display: none;
}
</style>